<template>
  <section class="news" style="background: #F8F8F8;">
    <v-img
      v-if="info.banners[0]"
      :src="info.banners[0].img"
      class="top-banner"
    >
      <v-layout
        align-center
        fill-height
        justify-center
        style="position: relative; text-align: center;"
      >
        <v-flex style="position: absolute; bottom: 40px;" class="ctk-about-submenu">
          <template>
            <v-tabs
              v-model="tabSubMenu"
              light
            >
              <v-tab
                v-for="(menu, k) in subMenu"
                :key="k"
                :href="'/news/' + (k+2)"
              >
                {{menu}}
              </v-tab>
            </v-tabs>
          </template>
        </v-flex>
      </v-layout>
    </v-img>
    <v-container  grid-list-md text-center class="ctk-div" v-if="detail">
      <v-layout wrap>
        <v-flex xs12 sm12 md9 lg9 class="text-left">
            <h3 style="font-size: 32px; color: #333; margin-bottom:20px;">{{detail.title}}</h3>
            <h6 style="font-size: 14px; color: #999; font-weight:normal; margin-bottom:20px;">{{publish}}: {{ detail.create_time }}</h6>
            <div  v-html="detail.content" class="ctk-news-content">
              {{detail.content}}
            </div>
        </v-flex>
        <v-flex xs12 sm12 md3 lg3>
          <ul style="list-style-type: none;">
            <li style="text-align: left;font-size:24px;color: #333;margin-bottom: 30px;">推荐阅读</li>
            <li v-for="(item, n) in recommend" :key="n" class="text-left" style="margin-bottom: 30px;">
              <a :href="'/news/detail/' + n" style="color:#333; font-size:16px;">{{item}}</a>
            </li>
          </ul>
        </v-flex>
      </v-layout>
    </v-container>
  </section>
</template>
<script>
export default {
  data: () => ({
    pageId: 4,
    introduce: null,
    introduceText: null,
    tabSubMenu: null,
    subMenu: null,
    detail: null,
    recommend: null,
    news: {
      'introduction': null
    }
  }),
  created () {
    document.querySelector('#menu_news').classList.add('v-btn--active')
    this.getPageData()
    this.getNew(this.$route.params)
  },
  watch: {
    '$store.getters.companyInfo': {
      handler: function (val, oldVal) {
        let locale = localStorage.getItem('locale')
        if (!locale) {
          locale = 'cn'
        }
        this.info.banners = val.banner[this.pageId]
      },
      deep: true
    }
  },
  computed: {
    info () {
      let banners = {}
      banners[this.pageId] = []
      let locale = localStorage.getItem('locale')
      if (!locale) {
        locale = 'cn'
      }
      if (this.$store.getters.companyInfo.banner) {
        banners = this.$store.getters.companyInfo.banner[this.pageId]
      }
      return {
        banners: banners
      }
    }
  },
  methods: {
    getPageData () {
      this.introduce = this.$t('news.introduce')
      this.introduceText = this.$t('news.introduceText')
      this.subMenu = this.$t('news.menu')
      this.detail = this.$t('detail')
      this.publish = this.$t('publish')
    },
    getNew (param) {
      this.https.get('new', param).then(response => {
        if (response.code === 0) {
          this.detail = response.data.detail
          this.recommend = response.data.recommend
          this.tabSubMenu = '/news/' + this.detail.type
        }
      })
    }
  }
}
</script>
<style>
  .ctk-news-content img {width:100%;}
</style>
